(function ($, site) {
  Drupal.behaviors.balmainCarouselHomepageV1 = {
    attached: false,

    attach: function (context) {
      if (this.attached) return;
      this.attached = true;

      var $formatter = $('.js-balmain-carousel-v1', context);
      var self = this;

      // Loop through and initialize the carousels
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carouselControlsNextLabel = site.translations?.elc_general?.next || 'next';
        var carouselControlsPreviousLabel = site.translations?.elc_general?.previous || 'previous';

        var carouselElements = {
          $currentCarousel: thisFormatter.find('.js-balmain-carousel'),
          $ourSlide: thisFormatter.find('.basic-image--responsive'),
          arrowsDiv: thisFormatter.find('.js-balmain-carousel__controls'),
          $slickCounter: thisFormatter.find('.js-balmain-carousel-formatter-counter'),
          $slickTitle: thisFormatter.find('.js-balmain-carousel__product-title'),
          $slickDescription: thisFormatter.find('.js-balmain-carousel__product-description'),
          $slickButton: thisFormatter.find('.js-balmain-carousel__button')
        };

        var $prevArrow = `
            <div class="slick-prev-button slick--custom">
              <svg role="img" aria-label="${carouselControlsPreviousLabel}" class="slick-prev">
                <use xlink:href="#icon--arrow-hollow-left"></use>
              </svg>
            </div>`;
        var $nextArrow = `
            <div class="slick-next-button slick--custom">
              <svg role="img" aria-label="${carouselControlsNextLabel}" class="slick-next">
                <use xlink:href="#icon--arrow-hollow-right"></use>
              </svg>
            </div>`;

        var autoplay = carouselElements.$currentCarousel.data('slides-autoplay') || false;
        var settings = {
          appendArrows: carouselElements.arrowsDiv,
          centerMode: true,
          slide: '.js-balmain-carousel__slide',
          slidesToShow: 1,
          variableWidth: true,
          autoplay: autoplay,
          prevArrow: $prevArrow,
          nextArrow: $nextArrow
        };

        carouselElements.$currentCarousel
          .not('.slick-initialized')
          .on('init afterChange', function (event, slick) {
            self.renderSlickProductData(slick, carouselElements);
          })
          .on('init', function () {
            thisFormatter.removeClass('js-hidden-until-slick');
          })
          .slick(settings);
      });
    },

    updateElement: function ($element, content, attr, attrValue) {
      if (content) {
        $element.html(content).show();
        if (attr && attrValue) {
          $element.attr(attr, attrValue);
        }
      } else {
        $element.hide();
      }
    },

    renderSlickProductData: function (slick, elements) {
      var currentSlide = slick.currentSlide + 1;
      var slideData = elements.$ourSlide.eq(slick.currentSlide).data();

      elements.$slickCounter.html(`${currentSlide}/${slick.slideCount}`);

      this.updateElement(elements.$slickTitle, slideData.title);
      this.updateElement(elements.$slickDescription, slideData.description);
      this.updateElement(elements.$slickButton, slideData.cta, 'href', slideData.cta_url || '#');
    }
  };
})(jQuery, window.site || {});
